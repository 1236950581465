import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  verifyToken: string;
  notifications: any[];
  loading: boolean;
  token: string;
  title: any;
  notificationType: any;
  notificationSelect: any;
  openNotify: boolean;
  notificationDecription: any;
  errorTitle: any;
  errorContents: any;
  errorType: any;
  patientList: any;
  addPatientList: any;
  selectedRadioMonth: any;
  disableFlag: any;
  notificationSelectedType:any;
  language: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PushnotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiNotificationsCallId: string = "";
  sendNotificationCallId: any;
  getAllPatientList: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      verifyToken: "",
      notifications: [],
      loading: true,
      token: "",
      title: "",
      notificationType: ["In App Notification", "Email Notification"],
      notificationSelect: "",
      openNotify: false,
      notificationDecription: "",
      errorTitle: "",
      errorContents: "",
      errorType: "",
      patientList: [],
      addPatientList: [],
      selectedRadioMonth: "",
      disableFlag: false,
      notificationSelectedType:"",
      language: localStorage.getItem('language') || 'en'
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.apiNotificationsCallId) {
        console.log("Notifications Response: ", responseJson);
        this.setState({ notifications: responseJson.data, loading: false });
      } else if (apiRequestCallId === this.sendNotificationCallId) {

        if (responseJson.errors) {
          this.callResponceErrors(responseJson.errors);
        } else {
          if(responseJson.message){
            alert(responseJson.message)
          }else if(responseJson.meta){
            alert(responseJson.meta.message)
            this.clearField()
          }
        }
      } else if (apiRequestCallId === this.getAllPatientList) {
        this.callResponcePatient(responseJson)
       
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.callAllPatient()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  handleSelect = (option: any, newArray: any) => {
    this.setState({
      addPatientList: newArray,
    })

  };
  deleteItem = (item1: any) => {
    const updatedItems = this.state.addPatientList.filter((item: any) => item.id !== item1.id);
    this.setState({ addPatientList: updatedItems })

  }
  handleLanguageSwitch = (lang: string) => {
    localStorage.setItem('language', lang)
    this.setState({ language: lang})
  }
  handleOptionChangeRadioMonth = (event: any) => {
    this.setState({ selectedRadioMonth: event.target.value });
    if (event.target.value === 'all') {
      this.setState({ disableFlag: true })
    } else {
      this.setState({ disableFlag: false })
    }
  };
  getInitials(name: any) {
    if (name) {
      const words = name.split(' ');
      const initials = words.map((word: string) => word.charAt(0).toUpperCase());
      return initials.join('');
    }
  }
  callResponcePatient=(responseJson:any)=>{
    if (responseJson) {
      const newData = responseJson.map((item: any) => ({
        id:item.data.id,
        full_name: item.data.attributes.full_name,
        user_name: item.data.attributes.user_name,
        email: item.data.attributes.email,
        profile_image:item.data.attributes.profile_image
      }));
      this.setState({ patientList: newData })
    }
  }
  getData = (data: any) => {
    this.setState({ addPatientList: data })
  }
  callAllPatient = () => {
    let token = localStorage.getItem("token")
    const header = {
      "Token": token
    };
    let studyNameStore = localStorage.getItem("studyName")
    let parseData;
    if (studyNameStore != null) {
      parseData = JSON.parse(studyNameStore)
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPatientList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPatientEndPoint + parseData.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNotificationsAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  callResponceErrors = (errors: any) => {
    if (errors.some((item: any) => item.hasOwnProperty('title'))) {
      this.setState({ errorTitle: "Title can't be blank", })
    }
    if (errors.some((item: any) => item.hasOwnProperty('contents'))) {
      this.setState({ errorContents: "Content can't be blank", })
    }
    if (errors.some((item: any) => item.hasOwnProperty('notification_type'))) {
      this.setState({ errorType: "Notification Type can't be blank", })
    }
  }
  clearField = () => {
    this.setState({ title: '', notificationDecription: "", notificationSelect: "", selectedRadioMonth:"", addPatientList: []})
  }
  handleOptionClick = (option: any) => {
    this.setState({ notificationSelect: option, openNotify: false, errorType: "" }, () => {
      if (this.state.notificationSelect === "Email Notification") {
        this.setState({ notificationSelectedType: "email" });
      } else {
        this.setState({ notificationSelectedType: "in app" });
      }
    });
  };
  
  changeTitle = (event: any) => {
    this.setState({ title: event.target.value, errorTitle: "" })
  }
  getNotifications(token: string) {
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiNotificationsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getNotificationsAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDescriptionChange = (event: any) => {
    this.setState({ notificationDecription: event.target.value, errorContents: "" })
  } 
  handleRedirection(notification: any) {
    let notification_type =
      notification.attributes?.options?.data?.notification_type;
    switch (notification_type) {
      default:
        console.log("Notifciation Selected::" + notification_type);
        break;
    }
  }
  callSendNotification = () => {
    let parseData;
    let token = localStorage.getItem("token")
    let studyNameStore = localStorage.getItem("studyName")
    if (studyNameStore != null) {
      parseData = JSON.parse(studyNameStore)
    }
   
    let arrayOfStrings = [];
    if (this.state.selectedRadioMonth === 'selected') {
      if (this.state.addPatientList) {
        arrayOfStrings = this.state.addPatientList.map((obj: any) => obj.email);
      }
    }else{
      arrayOfStrings = this.state.patientList.map((obj: any) => obj.email);
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": token
    };
    const bodyis = {
      "notification": {
        "title": this.state.title,
        "contents": this.state.notificationDecription,
        "notification_type": this.state.notificationSelectedType
      },
      "study_id": parseData?.id,
      "recipients": this.state.selectedRadioMonth,
      "selected_patients": arrayOfStrings
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postNotificationsendPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.posttNotificationsAPIMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyis)
    );
    this.sendNotificationCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
