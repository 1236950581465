import React from "react";

// Customizable Area Start
import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Grid, Hidden, InputAdornment, Paper, TextField, Typography, DialogContent, styled, CircularProgress, Snackbar, IconButton, Select, MenuItem } from "@material-ui/core";
import { DropdownLogo, LanguageLogo, backDrop, boxheaderLogo, buttonCancel, emailLogo, headerLogo, lockLogo, rightLogo, sideLogo } from "./assets";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import PrivacyPolicy from "../../termsconditions/src/PrivacyPolicy.web";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    logo = () => {
        return (
            <Grid item xs={12}>
                <Box className="form-logo">
                    <img src={boxheaderLogo} alt="boxheader-logo" />
                </Box>
            </Grid>
        )
    }

    Terms = () => {
        const {showTerms, showPrivacy} = this.state
        const languageMessages: { [key: string]: string } = {
            en: "By signing in, you're agreeing to Medical Trail App's ",
            fr: "En vous connectant, vous acceptez ",
            de: "Mit der Anmeldung stimmen Sie den Allgemeinen Geschäftsbedingungen und der Datenschutzrichtlinie der ",
            es: "Al iniciar sesión, acepta los "
          };
          const termsMessages: { [key: string]: string } = {
            en: " Terms and Conditions ",
            fr: " les conditions ",
            de: " App für medizinische Studien zu ",
            es: "Términos y condiciones "
          };
      
          const andMessages: { [key: string]: string } = {
            en: "and ",
            fr: "générales et la ",
            de: "und der ",
            es: "y la "
          };
      
          const privacyMessages: { [key: string]: string } = {
            en: "Privacy Policy ",
            fr: "Politique de Confidentialité ",
            de: "Datenschutzrichtlinie ",
            es: "Política de privacidad "
          };
      
          const appMessages: { [key: string]: string } = {
            en: "of Medical Trail App",
            fr: "de Medical Trail App",
            de: "der Medical Trail App zu",
            es: "de la aplicación Medical Trail"
          };
        return (
            <Grid item xs={12}>
                <Box className="form-tnc">
                    <Typography className="form-termsgroup">
                        {languageMessages[this.state.selectedLanguage] || languageMessages['en']}
                        <Typography className="form-terms"><Typography className="form-terms" component={'span'} id="TermsButton" onClick={() => this.handleTermsClick()}  >
                            {termsMessages[this.state.selectedLanguage] || termsMessages['en']}
                        </Typography>
                            <Typography className="form-termsgroup" component={"span"}>
                                {andMessages[this.state.selectedLanguage] || andMessages['en']}
                            </Typography>
                            <Typography className="form-terms" component={'span'} id="handlePrivacyButton" onClick={() => this.handlePrivacyClick()}>
                                {privacyMessages[this.state.selectedLanguage] || privacyMessages['en']}
                            </Typography>
                            <Typography className="form-termsgroup" component={'span'} id="handleClickPrivacy">
                                {appMessages[this.state.selectedLanguage] || appMessages['en']}
                            </Typography></Typography>
                    </Typography>
                    {showPrivacy && <PrivacyPolicy navigation={undefined} id={""} handleClosePrivacy={() => this.handleClosePrivacy()} />}
                    {showTerms && <TermsConditions navigation={undefined} id={""} handleCloseTerms={() => this.handleCloseTerms()} />}
                </Box>
            </Grid>
        )
    }

    language = () => {
        return (

            <Grid>
                <Box style={{ textAlign: 'center', marginTop: '33px' }}>
                    <img src={LanguageLogo} alt="LanguageLogo" style={{ verticalAlign: 'top' }} />
                    <Typography component={"span"} className="languageTxt">
                        Language:
                        <Select
                            className="englishTxt"
                            value={this.state.selectedLanguage}
                            id="handleRegistrationLanguage"
                            onChange={this.handleLanguageChangeRegistration}
                            style={{ marginLeft: '10px' }}
                            disableUnderline
                            IconComponent={ExpandMoreIcon}
                        >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="fr">French</MenuItem>
                            <MenuItem value="de">German</MenuItem>
                            <MenuItem value="es">Spanish</MenuItem>
                        </Select>
                    </Typography>
                </Box>
            </Grid>
        )
    }

    dialog = () => {
        const { password, confirmPassword, confirmPasswordError, passwordError } = this.state;
        const SetYourPassword: { [key: string]: string } = {
            en: "Set Your Password",
            fr: "Définissez votre mot de passe",
            de: "Legen Sie Ihr Passwort fest",
            es: "Establece tu contraseña"
          };
          const resetPassword: { [key: string]: string } = {
            en: "Please enter your new password for reset the password",
            fr: "Veuillez saisir votre nouveau mot de passe pour réinitialiser votre mot de passe",
            de: "Bitte geben Sie Ihr neues Passwort ein, um das Passwort zurückzusetzen",
            es: "Por favor ingrese su nueva contraseña para restablecer la contraseña"
          };
        return (

            <Dialog
                data-test-id="closeIcon"
                open={this.state.open}
                onClose={() => this.handleClose()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogStyle>
                    <DialogActions>
                        <img src={buttonCancel} alt="button-cancel-logo"
                            onClick={() => this.handleClose()} style={{ cursor: 'pointer' }} />
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">{SetYourPassword[this.state.selectedLanguage] || SetYourPassword['en']}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                        {resetPassword[this.state.selectedLanguage] || resetPassword['en']}
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                data-test-id="passwordClick"
                                type={this.state.showPassword ? 'text' : 'password'}                                
                                fullWidth
                                id="password"
                                variant="outlined"
                                placeholder="Enter New Password"
                                value={password}
                                onChange={(e) => { this.handlePasswordChange(e.target.value) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end" data-test-id="icon-test">
                                            {password.length > 0 ? (<IconButton
                                                id="password-Visible"
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowPassword()}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>) : null}
                                        </InputAdornment>
                                    ),
                                    className: "newPswd"
                                }}
                                error={passwordError !== ''}
                            />
                            <Typography className="passwordErrorShow">{this.state.passwordError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                 type={this.state.showConfirmPassword ? 'text' : 'password'} 
                                fullWidth
                                variant="outlined"
                                placeholder="Confirm New Password"
                                id="cPassword"
                                value={confirmPassword}
                                onChange={(e) => this.handleConfirmPasswordChange(e.target.value)}

                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {confirmPassword.length > 0 ? (<IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.handleClickShowConfirmPassword()}
                                            >
                                                {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>) : null}
                                        </InputAdornment>
                                    ),
                                    className: "ConfirmNewPswd"

                                }}
                                error={confirmPasswordError !== ''}
                                helperText={confirmPasswordError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined"
                                className="change-password-btn"
                                disabled={this.state.isLoading}
                                fullWidth onClick={() => this.submitPassWord()}>
                                {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> : "Create Password"}
                            </Button>
                        </Grid>
                    </DialogContent>

                </DialogStyle>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start        
        const { emailScreen, otpScreen, passwordSuccess, emailError, email, otp, timerActive, otpTimerReach } = this.state
        const trialMessages: { [key: string]: string } = {
            en: "Scientific trials testing vaccines for effectiveness and safety",
            fr: "Essais scientifiques testant l'efficacité et la sécurité des vaccins",
            de: "Wissenschaftliche Studien zur Prüfung von Impfstoffen auf Wirksamkeit und Sicherheit",
            es: "Ensayos científicos que prueban la eficacia y seguridad de las vacunas"
          };
      
          const emailPromptMessages: { [key: string]: string } = {
            en: "Please enter your email address to continue",
            fr: "Veuillez entrer votre adresse e-mail pour continuer",
            de: "Bitte geben Sie Ihre E-Mail-Adresse ein um fortzufahren",
            es: "Por favor introduce tu dirección de correo electrónico para continuar"
          };
      
          const emailPlaceholder: { [key: string]: string } = {
            en: "Email Address",
            fr: "Adresse e-mail",
            de: "E-Mail-Adresse",
            es: "Dirección de correo electrónico"
          };
      
          const buttonMessages: { [key: string]: string } = {
            en: "Continue",
            fr: "Continuer",
            de: "Weitermachen",
            es: "Continuar"
          };

          const otpMessages: { [key: string]: string } = {
            en: "Please enter the 6 Digit OTP and verify your email address",
            fr: "Veuillez entrer l'OTP à 6 chiffres et vérifier votre adresse e-mail",
            de: "Bitte geben Sie  hier den erhaltenen 6-stelligen Passcode ein und bestätigen Sie Ihre E-Mail-Adresse",
            es: "Por favor, ingrese el OTP de 6 dígitos y verifique su dirección de correo electrónico",
          };
      
          const editEmailText: { [key: string]: string } = {
            en: "Edit Email Address",
            fr: "Modifier l'adresse E-mail",
            de: "E-Mail-Adresse bearbeiten",
            es: "Editar dirección de correo electrónico",
          };
      
          const emailPlaceholders: { [key: string]: string } = {
            en: "Email Address",
            fr: "Adresse e-mail",
            de: "E-Mail-Adresse",
            es: "Dirección de correo electrónico",
          };
          const otpNotReceivedMessage: { [key: string]: string } = {
            en: "Didn't get the OTP?",
            fr: "Je n'ai pas obtenu l'OTP?",
            de: "Sie haben den 6 stelligen Code nicht erhalten??",
            es: "¿No obtuviste la OTP?"
          };
      
          const resendOTPMessage: { [key: string]: string } = {
            en: "Resend OTP",
            fr: "Renvoyer OTP",
            de: "Nochmals an E Mail Adresse schicken",
            es: "Reenviar OTP"
          };
          const welcomeText: { [key: string]: string } = {
            en: "Welcome to Patientist",
            fr: "Bienvenue chez Patientiste",
            de: "Willkommen bei Patientist ",
            es: "Bienvenido a Paciente"
          };
          const passwordCreated: { [key: string]: string } = {
            en: "Password Created Successfully",
            fr: "Mot de passe créé avec succès",
            de: "Passwort erfolgreich geändert",
            es: "Contraseña creada exitosamente"
          };
      
        return (
            <>
                <ModalStyle>
                    <Paper elevation={0} className="paper-container">
                        <Box style={{ position: 'sticky', top: 0, left: 0, right: 0 }}>
                            <header className="header">
                                <Container maxWidth="lg">
                                    <img src={headerLogo} alt="header-logo" style={{  height: "56px", maxWidth: "330px", width: "100%"}} />
                                </Container>
                            </header>
                        </Box>
                        <Container maxWidth="lg" style={{marginTop:'60px'}}>

                            <Grid container className="main" alignItems="center">
                                <Hidden smDown>
                                    <Grid item xs={12} md={6}>
                                        <Typography className="textPara" > 
                                        {trialMessages[this.state.selectedLanguage]}
                                        </Typography>
                                        <img src={sideLogo} alt="side-logo" />
                                    </Grid>
                                </Hidden>

                                {/* //---- Start Email Registration UI */}
                                {emailScreen && <Grid container item xs={12} md={6} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid container spacing={3} >
                                                {this.logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography >
                                                        {emailPromptMessages[this.state.selectedLanguage]}
                                                       </Typography>
                                        
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        onChange={(e) => this.handleEmailChange(e.target.value)}
                                                        variant="outlined"
                                                        placeholder={emailPlaceholder[this.state.selectedLanguage]}
                                                        id="email"
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailLogo} />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailError === false ? rightLogo : ""} />
                                                                </InputAdornment>
                                                            ),
                                                            style: { height: 49 }
                                                        }}
                                                        className="emailPlaceholder"
                                                        value={email}
                                                        error={emailError}
                                                        helperText={!emailError ? '' : this.state.emailErrorText}
                                                    />

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button variant="outlined"
                                                        disabled={this.state.isLoading}
                                                        className="continueBtn"
                                                        fullWidth onClick={() => { this.handleEmailSubmit() }}>
                                                        {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> :( 
                                                            buttonMessages[this.state.selectedLanguage])}
                                                    
                                                    </Button>
                                                </Grid>
                                                {this.Terms()}

                                            </Grid>
                                        </Box>
                                        {this.language()}
                                    </Grid>
                                </Grid>}

                                {/* //---- End Email Registration UI */}


                                {/* //---- Start OTP Verification UI */}

                                {otpScreen && <Grid container item xs={12} md={6} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid container spacing={3} className="form-content">
                                                {this.logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography className="enter-otp">
                                                        {otpMessages[this.state.selectedLanguage]}                                                            <Typography component={"span"} className="edit-email" onClick={this.handleEditEmailAddress}>
                                                        {editEmailText[this.state.selectedLanguage]}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        placeholder={emailPlaceholders[this.state.selectedLanguage]}
                                                        value={email}
                                                        disabled
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailLogo} />
                                                                </InputAdornment>
                                                            ),
                                                            style: { height: 49 }
                                                        }}

                                                        className="disabledemail"
                                                    />
                                                    <Box className="timer">  {this.displayTime()}</Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        id="otpInput"
                                                        placeholder="000000"
                                                        InputProps={{
                                                            className: "otp",
                                                        }}
                                                        onKeyDown={(e) => this.handleOtpChange(e)}
                                                        onChange={(e) => this.handleOtpChange(e)}
                                                        value={otp}
                                                        disabled={otpTimerReach}
                                                        error={this.state.validOtp}
                                                        helperText={this.state.validOtp ? this.state.validateOtpMsg : ''}
                                                    />
                                                </Grid>
                                                <Typography>{this.state.otpError}</Typography>
                                                <Grid item xs={12}>
                                                    <Box className="form-otp">
                                                        <Typography className="didnot-got">
                                                            {otpNotReceivedMessage[this.state.selectedLanguage]}
                                                            <Button variant={"text"}  
                                                            data-test-id="resend"                                                    
                                                            disabled={otpTimerReach ||timerActive}
                                                            className={`resend-otp ${otpTimerReach ||timerActive ? 'active' : 'inactive'}`}
                                                            onClick={this.handleResendOtp}                                                            
                                                            >
                                                                {resendOTPMessage[this.state.selectedLanguage]}
                                                            </Button>
                                                            <Snackbar
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                open={this.state.openSnackbar}
                                                                autoHideDuration={3000}
                                                                onClose={this.handleSnackbarClose}
                                                                message="OTP Resent!"
                                                                action={
                                                                    <Button color="secondary" size="small" onClick={this.handleSnackbarClose}>
                                                                        <img src={buttonCancel} alt="cancelBtn" />
                                                                    </Button>

                                                                }
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button variant="outlined" className="submitBtn" fullWidth
                                                        disabled={this.state.isLoading || otpTimerReach}
                                                        onClick={() => this.handleClickOpen()}>
                                                        {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> : "Submit"}
                                                    </Button>
                                                    {this.dialog()}
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.language()}
                                    </Grid>
                                </Grid>}
                                {/* //---- End OTP Verification UI */}



                                {/* //---- Start Password Created Successfully UI */}
                                {passwordSuccess && (<Grid container item xs={12} md={6} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid container spacing={3} >
                                                {this.logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography className="form-welcome">{welcomeText[this.state.selectedLanguage] || welcomeText['en']}</Typography>
                                                        <Typography className="form-created">{passwordCreated[this.state.selectedLanguage] || passwordCreated['en']} </Typography>

                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button variant="outlined" className="loginPswrdBtn" id="btn-navigate" onClick={()=>this.loginNavigationButton()} fullWidth>Login with password</Button>
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.language()}
                                    </Grid>
                                </Grid>)}
                                {/* //---- End Password Created Successfully UI*/}

                            </Grid>
                        </Container>
                    </Paper>
                </ModalStyle>
            </>
        );
        // Customizable Area End

    }
}




// Customizable Area Start

const ModalStyle = styled(Box)({
    "& .paper-container": {
        height: "100vh",
        // overflow: "hidden",
        overflowX: 'hidden',
        backgroundImage: `url(${backDrop})`
    },
    "& .header": {
        padding: '30px 0',
        backgroundColor: "#fff",
    },
    "& .paper": {
        padding: 2,
        textAlign: 'center',
    },
    "& .textPara": {
        color: "#0F2220",
        fontFamily: "Jost",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
        marginLeft: '20px',
        marginBottom: '100px',

    },
    "& .box": {
        borderRadius: "10px",
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        alignItems: 'center',
        padding: 30
    },
    "& .emailPlaceholder": {
        color: "#0F2220",
        fontFamily: "Jost",
        fontSize: "30px",
        marginTop: "56px",
    },
    "& .form-logo": {
        textAlign: "center",
        color: "#767E85",
    },
    "& .continueBtn": {
        marginTop: 97,
        height: 50,
        textTransform: 'capitalize',
        borderRadius: "4px",
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        fontFamily: "Jost",
        fontSize: "16px",
        color: "#0F2220",
        fontWeight: 'bolder'
    },
    "& .form-tnc": {
        textAlign: 'center',

    },
    "& .form-termsgroup": {
        color: "#2B2B2B",
        fontFamily: 'Jost',
        fontSize: '12px',
        fontWeight: 400,
    },
    "& .form-terms": {
        color: "#A046FB",
        fontFamily: 'Jost',
        fontSize: '12px',
        fontWeight: 400,
        cursor: 'pointer'
    },
    "& .languageTxt": {
        color: "#000",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "bold",
        marginLeft: '4px'
    },
    "& .englishTxt": {
        color: "#A046FB",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "bold",
        marginLeft: '4px',
    },

    "& .enter-otp": {
        color: "#767E85",
        textAlign: "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        cursor: 'pointer'

    },

    "& .edit-email": {
        color: "#A046FB",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
    },

    "& .form-content": {
        textAlign: 'center'
    },

    "& .disabledemail": {
        color: "#0F2220",
        fontFamily: "Jost",
        fontSize: "30px",
    },

    "& .otp": {
        height: "49px",
        marginTop:'-20px',
    },

    "& .form-otp": {
        textAlign: 'end',
        marginTop:'-20px',
    },

    "& .didnot-got": {
        color: "#767E85",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
    },

    "& .resend-otp.active": {
        color: "#767E85 !important",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        marginLeft: '6px',
        cursor: 'pointer'
    },
    "& .resend-otp.inactive": {
        fontWeight: 600,
        marginLeft: '6px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        color: "#A046FB !important",
    },
    "& .resend-otp": {
        fontWeight: 600,
        marginLeft: '6px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        color: "#767E85 !important",
        cursor: 'pointer'
    },
    "& .submitBtn": {
        height: 50,
        textTransform: 'capitalize',
        borderRadius: "4px",
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        fontFamily: "Jost",
        fontSize: "16px",
        color: "#0F2220",
        fontWeight: 'bolder'
    },
    "& .timer": {
        marginTop:'10px',
        textAlign: 'end',
        color: "#7D7D7D",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400
    },



    "& .form-welcome": {
        color: "#535655",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: "bolder",
    },
    "& .form-created": {
        color: "#767E85",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        marginTop: '16px'
    },
    "& .loginPswrdBtn": {
        marginTop: '140px',
        height: '48px',
        borderRadius: "2px",
        border: "2px solid #A046FB",
        background: "#A046FB",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        color: '#fff',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        fontFamily: 'Jost'
    },
    "& .welcome": {
        color: "#535655",
        fontFamily: "Inter",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 600
    },

    "& .loginEmail": {
        marginTop: '20px',
        color: "var(--grey-base, #939393)",
        fontFamily: "Jost",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400
    },
    "& .loginPswd": {
        color: "var(--grey-base, #939393)",
        fontFamily: "Jost",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400
    },

    "& .forgetPswd": {
        textAlign: 'end',
        color: "#A046FB",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        cursor: 'pointer'
    },
    "& .btn-grid": {
        textAlign: "center"
    },
    "& .loginBtn": {
        color: '#fff',
        borderRadius: "2px",
        border: "2px solid #A046FB",
        background: "#A046FB",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        textTransform: 'capitalize',
        fontFamily: 'Inter',
        height: '48px',
        width: '90%',

    }

})
const DialogStyle = styled(Box)({
    "& .passwordErrorShow":{
        maxWidth:"300px",
        fontSize:"12px",
        color:"#F44336",
        fontFamily:"Inter",
        marginTop:"5px",
        marginLeft:"14px"
    },
    "& .dialog-title": {
        color: "#000",
        fontFamily: "Jost",
        fontSize: "28px",
        fontWeight: "bolder",
    },
    "& .dialog-para": {
        color: "#5E5E5E",
        fontFamily: "Jost",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        width: '90%'
    },
    "& .change-password-btn": {
        height: '50px',
        color: '#000',
        fontFamily: 'Jost',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 'bolder',
        marginBottom: '40px',
        borderRadius: "4px",
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        marginTop: '32px',
    },

    "& .newPswd": {
        fontFamily: 'Jost',
        height: '49px',
        marginTop: '24px',

    },

    "& .ConfirmNewPswd": {
        scrollSnapMarginTop: '20px',
        marginTop: '20px',
        fontFamily: 'Jost',
        height: '49px',

    }
})
// Customizable Area End

