import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  showStudy: boolean;
  selectedStudy:any;
  total_Milestones : number,
  current_Milestones : number,
  percentage : number,
  milestones : any,
  sites : any,
  studyDescription : any,
  studyName : string,
  studyNumber : string,
  language: string
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getStudyApiCallId:any;
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      showStudy: true,
      selectedStudy:"",
      total_Milestones : 0,
      current_Milestones : 0,
      percentage : 0,
      milestones : [],
      sites : [],
      studyDescription : '',
      studyName : '',
      studyNumber : '',
      language: localStorage.getItem('language') || 'en'
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({
      selectedStudy : localStorage.getItem('studyNumber')
    },()=>{
      this.getStudyDetails();
    });
    this.setSessionTimeout();
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false
          });
        } else {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",
            loading: false
          });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false
          });
        }
      }

      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if(apiRequestCallId === this.getStudyApiCallId){
          console.log("resp",responseJson)
          this.setState({
            milestones : responseJson?.data.attributes.milestone_managements.data,
            sites : responseJson?.data.attributes.site_ids.data,
            studyDescription : responseJson?.data.attributes.study_description,
            studyName : responseJson?.data.attributes.study_name,
            studyNumber : responseJson?.data.attributes.study_number
          },()=>{
            this.calculateCurrentMilestone(this.state.milestones);
          })
        }
      
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getStudyDetails = () => {
    let parseData;
    let studyNameStore = localStorage.getItem("studyName")
    if (studyNameStore != null) {
      parseData = JSON.parse(studyNameStore)
    }

    let token = typeof window !== 'undefined' ? localStorage.getItem("token") : null
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStudyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudyApiCallIdEndPoint+parseData?.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  calculateDuration = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.floor(diffDays / 30); 
    const remainingDays = diffDays % 30;
  
    switch (true) {
      case diffMonths > 0 && remainingDays === 0:
        return `${diffMonths} month${diffMonths > 1 ? 's' : ''}`;
      case diffMonths > 0 && remainingDays > 0:
        return `${diffMonths} month${diffMonths > 1 ? 's' : ''}, ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
      default:
        return `${diffDays} day${diffDays > 1 ? 's' : ''}`;
    }
  };
  
  handleLanguageSwitch = (language: string) => {
    this.setState({ language: language })
    localStorage.setItem('language', language)
  }

  calculateCurrentMilestone = (milestones: any) => {
    if (!milestones || milestones.length === 0) {
      return 0;
    }
    const today = new Date();
    let currentMilestoneIndex: number = 0;
    let totalDays = 0;
    let completedDays = 0;
  
    milestones.sort((a: any, b: any) => {
      return new Date(a.attributes.start_date).getTime() - new Date(b.attributes.start_date).getTime();
    });
    for (let index = 0; index < milestones.length; index++) {
      const milestone = milestones[index];
      const startDate = new Date(milestone.attributes.start_date);
      const endDate = new Date(milestone.attributes.end_date);
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
      totalDays += diffDays;
  
      if (today >= startDate && today <= endDate) {
        if (currentMilestoneIndex === 0) {
          currentMilestoneIndex = index + 1;
        }
        completedDays += Math.ceil((today.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
      } else if (today > endDate) {
        completedDays += diffDays;
      }
    }
  
    let percentage = 0;
    if (currentMilestoneIndex !== 0) {
      percentage = (completedDays / totalDays) * 100;
    }
  
  
    this.setState({
      current_Milestones: currentMilestoneIndex,
      total_Milestones: milestones.length,
      percentage: Math.round(percentage),
    });
    return currentMilestoneIndex;
  };

formatDate(dateString: any): string {
  const dateParts: string[] | undefined = dateString?.split('-');
  const day: number = parseInt(dateParts?.[2] || "0", 10);
  const month: string = this.getMonthName(parseInt(dateParts?.[1] || "0", 10));
  const year: number = parseInt(dateParts?.[0] || "0", 10);

  const formattedDay: string = this.getFormattedDay(day);

  return `${formattedDay} ${month} ${year}`;
}
callPercentage = () => {
  return this.state.percentage;
};
getMonthName(monthNumber: number): string {
  const months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return months[monthNumber - 1] || "";
}

getFormattedDay(day: number): string {
  if (day >= 11 && day <= 13) {
      return day + 'th';
  }
  switch (day % 10) {
      case 1:
          return day + 'st';
      case 2:
          return day + 'nd';
      case 3:
          return day + 'rd';
      default:
          return day + 'th';
  }
}

formatNumber=(num : number)=>{
    if(num < 10) return '0'+num;
    else return num;
}

calculateTotalDuration = (milestones: any[]) => {
  let totalDays = 0;

  milestones?.forEach((milestone: any) => {
      const { start_date, end_date } = milestone.attributes || {};
      if(!start_date || !end_date) return;
      const [startYear, startMonth, startDay] = start_date.split('-').map(Number);
      const [endYear, endMonth, endDay] = end_date.split('-').map(Number);
      const startDate = new Date(startYear, startMonth - 1, startDay); 
      const endDate = new Date(endYear, endMonth - 1, endDay); 
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      totalDays += diffDays;
  });

  const totalMonths = Math.floor(totalDays / 30);
  const remainingDays = totalDays % 30;

  switch (true) {
      case totalMonths > 0 && remainingDays === 0:
          return `${totalMonths} month${totalMonths > 1 ? 's' : ''}`;
      case totalMonths > 0 && remainingDays > 0:
          return `${totalMonths} month${totalMonths > 1 ? 's' : ''}, ${remainingDays} day${remainingDays > 1 ? 's' : ''}`;
      default:
          return `${totalDays} day${totalDays > 1 ? 's' : ''}`;
  }
};
setSessionTimeout = () => {
  const SESSION_TIMEOUT = 24 * 60 * 60 * 1000
  const sessionStart = Date.now();
  
  setInterval(() => {
    const currentTime = Date.now();
    if (currentTime - sessionStart > SESSION_TIMEOUT) {
      this.handleSessionTimeout();
    }
  }, 1000);
};
handleSessionTimeout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('studyName');
  
  if (this.isPlatformWeb()) {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  } 
};

  // Customizable Area End

}
