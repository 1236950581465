import React from "react";

// Customizable Area Start
import { Box, Button, Container, Dialog, DialogActions, DialogTitle, Grid, Hidden, InputAdornment, Paper, TextField, Typography, DialogContent, styled, CircularProgress, Snackbar, IconButton, Select, MenuItem } from "@material-ui/core";
import { DropdownLogo, LanguageLogo, backDrop, boxheaderLogo, buttonCancel, emailLogo, headerLogo, lockLogo, rightLogo, sideLogo } from "./assets";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import ForgotPasswordController, {
    Props
} from "./ForgotPasswordController";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import PrivacyPolicy from "../../termsconditions/src/PrivacyPolicy.web";

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    Logo = () => {
        return (
            <Grid item xs={12}>
                <Box className="form-logo">
                    <img src={boxheaderLogo} alt="boxheader-logo" />
                </Box>
            </Grid>
        )
    }

    Terms = () => {
        const {showPrivacy, showTerms } = this.state
        const signInMessage: { [key: string]: string } = {
            en: "By signing in, you're agreeing to Medical Trail App's ",
            fr: "En vous connectant, vous acceptez ",
            es: "Al iniciar sesión, acepta los ",
            de: "Mit der Anmeldung stimmen Sie den Allgemeinen Geschäftsbedingungen und der Datenschutzrichtlinie der ",
          };
      
          const termsMessage: { [key: string]: string } = {
            en: "Terms and Conditions ",
            fr: "les conditions ",
            es: "Términos y condiciones ",
            de: "App für medizinische Studien zu ",
          };
      
          const generalAndMessage: { [key: string]: string } = {
            en: "and ",
            fr: "générales et la ",
            es: "y la ",
            de: "und der ",
          };
      
          const privacyMessage: { [key: string]: string } = {
            en: "Privacy Policy ",
            fr: "Politique de Confidentialité ",
            es: "Política de privacidad ",
            de: "Datenschutzrichtlinie ",
          };
        return (
            <Grid item xs={12}>
                <Box className="form-tnc">
                    <Typography className="form-termsgroup">
                    {signInMessage[this.state.selectedLanguage] || signInMessage['en']}
                        <Typography className="form-terms"><Typography className="form-terms" component={'span'} id="handleTermsBtn" onClick={()=>this.handleTermsClick()} > 
                        {termsMessage[this.state.selectedLanguage] || termsMessage['en']}
                        </Typography> 
                            <Typography className="form-termsgroup" component={"span"}>
                                {generalAndMessage[this.state.selectedLanguage] || generalAndMessage['en']}
                            </Typography>
                            <Typography className="form-terms" component={'span'} id="handlePrivacyBtn"  onClick={()=>this.handlePrivacyClick()}>
                            {privacyMessage[this.state.selectedLanguage] || privacyMessage['en']}
                                </Typography></Typography>
                    </Typography>
                    {showTerms && <TermsConditions navigation={undefined} id={""} handleCloseTerms={() =>this.handleCloseTerms()} />}
                    {showPrivacy && <PrivacyPolicy navigation={undefined} id={""} handleClosePrivacy={() => this.handleClosePrivacy()}/>}
                </Box>
            </Grid>
        )
    }

    Language = () => {
        const languageLabelForgotMessages: { [key: string]: string } = {
            en: "Language:",
            fr: "Langue:",
            de: "Sprache:",
            es: "Idioma:"
          };
        return (

            <Grid>
                <Box style={{marginTop: '33px', textAlign: 'center',  }}>
                    <img src={LanguageLogo} style={{ verticalAlign: 'top' }} alt="Language-Logo"  />
                    <Typography  className="languageTxt" component={"span"}>
                    {languageLabelForgotMessages[this.state.selectedLanguage] || languageLabelForgotMessages['en']}
                    <Select
                            className="englishTxt"
                            value={this.state.selectedLanguage}
                            id="handleForgotPassLanguage"
                            onChange={this.handleLanguageChangeRegistration}
                            style={{ marginLeft: '10px' }}
                            disableUnderline
                            IconComponent={ExpandMoreIcon}
                        >
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="fr">French</MenuItem>
                            <MenuItem value="de">German</MenuItem>
                            <MenuItem value="es">Spanish</MenuItem>
                        </Select>
                    </Typography>
                </Box>
            </Grid>
        )
    }
    Dialog = () => {
        const { password, confirmPassword, confirmPasswordError, passwordError } = this.state;

        const resetPasswordTitle: { [key: string]: string } = {
            en: "Re-set Your Password",
            fr: "Réinitialisez votre mot de passe",
            de: "Setzen Sie Ihr Passwort zurück",
            es: "Restablecer su contraseña"
          };
        const emailVerificationMessage: { [key: string]: string } = {
            en: "Please enter your email address for OTP verification",
            fr: "Veuillez entrer votre adresse e-mail pour la vérification OTP",
            de: "Bitte geben Sie Ihre E-Mail-Adresse für die OTP-Überprüfung ein",
            es: "Por favor ingrese su dirección de correo electrónico para la verificación OTP"
          };
          const placeholders: { [key: string]: { newPassword: string, confirmPassword: string } } = {
            en: { newPassword: "Enter New Password", confirmPassword: "Confirm New Password" },
            fr: { newPassword: "Entrez un nouveau mot de passe", confirmPassword: "Confirmez le nouveau mot de passe" },
            de: { newPassword: "Neues Passwort eingeben", confirmPassword: "Neues Passwort bestätigen" },
            es: { newPassword: "Introduzca nueva contraseña", confirmPassword: "Confirmar nueva contraseña" }
          };

          const createPasswordButtonText: { [key: string]: string } = {
            en: "Create Password",
            fr: "Créer un mot de passe",
            de: "Passwort erstellen",
            es: "Crear contraseña"
          };
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.handleClose()}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogStyle>
                    <DialogActions>
                        <img src={buttonCancel} alt="button-cancel-logo"
                            data-test-id="cancel-password"
                            onClick={() => this.handleClose()} style={{ cursor: 'pointer' }} />
                    </DialogActions>
                    <DialogTitle >
                        <Typography className="dialog-title">{resetPasswordTitle[this.state.selectedLanguage] || resetPasswordTitle['en']}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography className="dialog-para">
                        {emailVerificationMessage[this.state.selectedLanguage] || emailVerificationMessage['en']}
                        </Typography>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="password"
                                type={this.state.showPasswordForget ? 'text' : 'password'}                                
                                value={password}
                                onChange={(e) => { this.handlePasswordChange(e.target.value) }}
                                placeholder={placeholders[this.state.selectedLanguage]?.newPassword || placeholders['en'].newPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {password.length > 0 ? (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowPasswordForget()}
                                                    >
                                                        {this.state.showPasswordForget ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            ) : null}
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),
                                    className: "newPswd"
                                }}
                                inputProps={{ 'data-test-id': "pass-word" }}
                                error={passwordError !== ''}
                            />
                            <Typography className="passwordError">{this.state.passwordError}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                placeholder={placeholders[this.state.selectedLanguage]?.confirmPassword || placeholders['en'].confirmPassword}
                                value={confirmPassword}
                                variant="outlined"
                                id="cPassword"
                                onChange={(e) => this.handleConfirmPasswordChange(e.target.value)}
                                type={this.state.showConfirmPasswordForget ? 'text' : 'password'}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {confirmPassword.length > 0 ? (
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => this.handleClickShowConfirmPasswordForget()}
                                                    >
                                                        {this.state.showConfirmPasswordForget ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            ) : null}
                                        </InputAdornment>
                                    ),
                                    className: "ConfirmNewPswd",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={lockLogo} />
                                        </InputAdornment>
                                    ),

                                }}
                                inputProps={{ 'data-test-id': "confirm-password" }}
                                helperText={confirmPasswordError}
                                error={confirmPasswordError !== ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined"
                                data-test-id="change-pswd"
                                className="change-password-btn"
                                disabled={this.state.isLoading}
                                fullWidth onClick={() => this.submitPassWord()}>
                                {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> : (createPasswordButtonText[this.state.selectedLanguage] || createPasswordButtonText['en'])}
                            </Button>
                        </Grid>
                    </DialogContent>

                </DialogStyle>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start        
        const { emailScreen, otpScreen, passwordSuccess, emailError, email, otp, timerActive, otpTimerReach } = this.state;
        const scientificTrialsMessage: { [key: string]: string } = {
            en: "Scientific trials testing vaccines for effectiveness and safety",
            fr: "Essais scientifiques testant l’efficacité et la sécurité des vaccins",
            de: "Wissenschaftliche Studien zur Prüfung von Impfstoffen auf Wirksamkeit und Sicherheit",
            es: "Ensayos científicos que prueban la eficacia y seguridad de las vacunas"
          };
      
          const emailOTPVerificationMessage: { [key: string]: string } = {
            en: "Please enter your email address for OTP verification",
            fr: "Veuillez entrer votre adresse e-mail pour OTP vérification",
            de: "Bitte geben Sie Ihre E-Mail-Adresse für die OTP-Überprüfung ein",
            es: "Por favor, ingrese su dirección de correo electrónico para la verificación de OTP"
          };
      
          const emailPlaceholderMessage: { [key: string]: string } = {
            en: "Email Address",
            fr: "Adresse e-mail",
            de: "E-Mailadresse",
            es: "Dirección de correo electrónico"
          };

          const enterOTPMessage: { [key: string]: React.ReactNode } = {
            en: <>Please enter the 6-digit OTP and verify your <br /> email address</>,
            fr: <>Veuillez saisir l'OTP à 6 chiffres et vérifier votre <br /> adresse e-mail</>,
            de: <>Bitte geben Sie die 6-stellige OTP ein und verifizieren Sie Ihre <br /> E-Mail-Adresse</>,
            es: "Por favor, ingrese el OTP de 6 dígitos y verifique su dirección de correo electrónico"
          };
      
          const editEmailMessage: { [key: string]: string } = {
            en: "Edit Email Address",
            fr: "Modifier l'adresse E-mail",
            de: "E-Mail-Adresse bearbeiten",
            es: "Editar dirección de correo electrónico"
          };
      
          const otpNotReceivedMessage: { [key: string]: string } = {
            en: "Didn't get the OTP?",
            fr: "Je n'ai pas obtenu l'OTP?",
            de: "Sie haben den 6 stelligen Code nicht erhalten?",
            es: "¿No obtuviste la OTP?"
          };
      
          const resendOTPMessage: { [key: string]: string } = {
            en: "Resend OTP",
            fr: "Renvoyer OTP",
            de: "Nochmals an E Mail Adresse schicken",
            es: "Reenviar OTP"
          };
          const verifyButtonText: { [key: string]: string } = {
            en: "Verify",
            fr: "Vérifier",
            de: "Verifizieren",
            es: "Verificar"
          };
          const submitButtonText: { [key: string]: string } = {
            en: "Submit",
            fr: "Soumettre",
            de: "Einreichen",
            es: "Entregar"
          };

          const messages: { [key: string]: { welcome: string, success: string } } = {
            en: { welcome: "Welcome to Patientist", success: "Password Created Successfully" },
            fr: { welcome: "Bienvenue chez Patientiste", success: "Mot de passe créé avec succès" },
            de: { welcome: "Willkommen bei Patientist ", success: "Passwort erfolgreich geändert" },
            es: { welcome: "Bienvenido a Paciente", success: "Contraseña creada con éxito" }
          };
      
          const loginButtonText: { [key: string]: string } = {
            en: "Login with password",
            fr: "Se connecter avec le mot de passe",
            de: "Mit Passwort anmelden",
            es: "Iniciar sesión con la contraseña"
          };
        return (
            <>
            <Snackbar
              open={this.state.openSnackbarInvalidToken}
              onClose={this.handleSnackbarTokenClose}
              message="Invalid Session!"
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
              autoHideDuration={3000}
              action={
                  <Button size="small" color="secondary" onClick={this.handleSnackbarTokenClose}>
                      <img src={buttonCancel} alt="cancelBtn" />
                  </Button>
              }
            />
                <ModalStyle>
                    <Paper className="paper-container" elevation={0} >
                        <Box style={{ position: 'sticky', right: 0, top: 0, left: 0, }}>
                            <header className="header">
                                <Container maxWidth="lg">
                                    <img src={headerLogo} style={{ width: '30%' }} alt="header-logo" />
                                </Container>
                            </header>
                        </Box>
                        <Container maxWidth="lg">
                            <Grid className="main" container alignItems="center">
                                <Hidden smDown>
                                    <Grid item md={6} xs={12}>
                                        <Typography className="textPara">
                                        {scientificTrialsMessage[this.state.selectedLanguage] || scientificTrialsMessage['en']}
                                        </Typography>
                                        <img alt="side-logo" src={sideLogo} />
                                    </Grid>
                                </Hidden>
                                {/* //---- Start Email Registration UI */}
                                {emailScreen && <Grid container item xs={12} md={6} justifyContent="center">
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid spacing={3} container>
                                                {this.Logo()}
                                                <Grid xs={12} item>
                                                    <Box className="form-logo">
                                                        <Typography className="pleaseText">
                                                        {emailOTPVerificationMessage[this.state.selectedLanguage] || emailOTPVerificationMessage['en']}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid xs={12} item >
                                                    <TextField
                                                        onChange={(e) => this.handleEmailChange(e.target.value)}
                                                        id="email"
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder={emailPlaceholderMessage[this.state.selectedLanguage] || emailPlaceholderMessage['en']}
                                                        InputProps={{
                                                            style: { height: 49 },
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailError === false ? rightLogo : ""} />
                                                                </InputAdornment>
                                                            ),
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailLogo} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        error={emailError}
                                                        className="emailPlaceholder"
                                                        value={email}
                                                        inputProps={{ "data-test-id": "email-address" }}
                                                        helperText={!emailError ? '' : this.state.emailErrorText}
                                                    />

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button variant="outlined"
                                                        data-test-id="verify"
                                                        className="continueBtn"
                                                        disabled={this.state.isLoading}
                                                        fullWidth onClick={() => { this.handleEmailSubmit() }}>
                                                        {this.state.isLoading ? <CircularProgress size={30} style={{ color: "#A046FB" }} /> : ( verifyButtonText[this.state.selectedLanguage] || verifyButtonText['en'])}
                                                    </Button>
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.Language()}
                                    </Grid>
                                </Grid>}
                                {otpScreen && <Grid container item md={6} xs={12} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid className="form-content" container spacing={3} >
                                                {this.Logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography className="enter-otp">
                                                        {enterOTPMessage[this.state.selectedLanguage] || enterOTPMessage['en']}
                                                            <Typography className="edit-email" component={"span"} onClick={this.handleEditEmailAddress}>
                                                            {editEmailMessage[this.state.selectedLanguage] || editEmailMessage['en']}
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Email Address"
                                                        fullWidth
                                                        disabled
                                                        value={email}
                                                        className="disabledemail"
                                                        InputProps={{
                                                            style: { height: 49 },
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={emailLogo} />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <Box className="timer"> {this.displayTime()}</Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        InputProps={{
                                                            className: "otp",
                                                        }}
                                                        variant="outlined"
                                                        onChange={(e) => this.handleOtpChange(e)}
                                                        value={otp}
                                                        id="otpInput"
                                                        fullWidth
                                                        placeholder="000000"
                                                        inputProps={{ "data-test-id": 'otp-input' }}
                                                        error={this.state.validOtp}
                                                        onKeyDown={(e) => this.handleOtpChange(e)}
                                                        disabled={otpTimerReach}
                                                        helperText={this.state.validOtp ? this.state.validateOtpMsg : ''}
                                                    />
                                                </Grid>
                                                <Typography>{this.state.otpError}</Typography>
                                                <Grid item xs={12}>
                                                    <Box className="form-otp">
                                                        <Typography className="didnot-got">
                                                        {otpNotReceivedMessage[this.state.selectedLanguage] || otpNotReceivedMessage['en']}
                                                            <Button
                                                              variant="text"
                                                              disabled={otpTimerReach || timerActive}
                                                              className={`resend-otp ${timerActive || otpTimerReach? 'active' : 'inactive'}`}
                                                              onClick={this.handleResendOtp}
                                                            >
                                                            {resendOTPMessage[this.state.selectedLanguage] || resendOTPMessage['en']}
                                                            </Button>
                                                            <Snackbar
                                                                open={this.state.openSnackbar}
                                                                onClose={this.handleSnackbarClose}
                                                                message="OTP Resent!"
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                                autoHideDuration={3000}
                                                                action={
                                                                    <Button size="small" color="secondary" onClick={this.handleSnackbarClose}>
                                                                        <img src={buttonCancel} alt="cancelBtn" />
                                                                    </Button>
                                                                }
                                                            />
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button
                                                        disabled={this.state.isLoading || this.state.otpTimerReach}
                                                        className="submitBtn" fullWidth
                                                        data-test-id="submit-button"
                                                        variant="outlined"
                                                        onClick={() => this.handleClickOpen()}>
                                                        {this.state.isLoading ? <CircularProgress style={{ color: "#A046FB" }} size={30} /> : (submitButtonText[this.state.selectedLanguage] || submitButtonText['en'])}
                                                    </Button>
                                                    {this.Dialog()}
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.Language()}
                                    </Grid>
                                </Grid>}

                                {passwordSuccess && (<Grid container item md={6} xs={12} justifyContent="center" >
                                    <Grid item xs={10} >
                                        <Box className="box">
                                            <Grid spacing={3} container >
                                                {this.Logo()}
                                                <Grid item xs={12}>
                                                    <Box className="form-logo">
                                                        <Typography className="form-welcome">{messages[this.state.selectedLanguage]?.welcome || messages['en'].welcome}</Typography>
                                                        <Typography className="form-created">{messages[this.state.selectedLanguage]?.success || messages['en'].success}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button className="loginPswrdBtn" variant="outlined" id="handleLoginNavigate" onClick={() => this.loginNavigationButton()} fullWidth>{loginButtonText[this.state.selectedLanguage] || loginButtonText['en']}</Button>
                                                </Grid>
                                                {this.Terms()}
                                            </Grid>
                                        </Box>
                                        {this.Language()}
                                    </Grid>
                                </Grid>)}
                            </Grid>
                        </Container>
                    </Paper>
                </ModalStyle>
            </>
        );
        // Customizable Area End

    }
}




// Customizable Area Start

const ModalStyle = styled(Box)({
    "& .header": {
        backgroundColor: "#fff",
        padding: '30px 0',
    },
    "& .paper-container": {
        backgroundImage: `url(${backDrop})`,
        height: "100vh",
        overflowX: 'hidden',
    },
    "& .main": {
        height: "calc(100vh - 80px)",
    },
    "& .pleaseText": {
        fontWeight: 400,
        textAlign: "center",
        fontFamily: "Inter",
        color: "#767E85",
        fontSize: "14px",
        fontStyle: "normal",
        fontFeatureSettings: "'clig' off, 'liga' off",
    },
    "& .paper": {
        textAlign: 'center',
        padding: 2,
    },
    "& .box": {
        padding: 30,
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        borderRadius: "10px",
        alignItems: 'center',
        background: "#FFF",
        border: "2px solid #A046FB",
    },
    "& .textPara": {
        fontStyle: "normal",
        marginBottom: '100px',
        textTransform: "capitalize",
        fontFamily: "Jost",
        marginLeft: '20px',
        fontSize: "30px",
        fontWeight: 700,
        color: "#0F2220",
    },
    "& .form-logo": {
        color: "#767E85",
        textAlign: "center",
    },
    "& .emailPlaceholder": {
        marginTop: "56px",
        color: "#0F2220",
        fontSize: "30px",
        fontFamily: "Jost",
    },
    "& .form-tnc": {
        textAlign: 'center',
    },
    "& .continueBtn": {
        height: 50,
        marginTop: 97,
        borderRadius: "4px",
        textTransform: 'capitalize',
        background: "#FFF",
        border: "2px solid #A046FB",
        fontFamily: "Jost",
        fontWeight: 'bolder',
        fontSize: "16px",
        color: "#0F2220",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
    },
    "& .form-terms": {
        fontWeight: 400,
        cursor: 'pointer',
        fontFamily: 'Jost',
        fontSize: '12px',
        color: "#A046FB",
    },
    "& .form-termsgroup": {
        fontFamily: 'Jost',
        fontWeight: 400,
        fontSize: '12px',
        color: "#2B2B2B",
    },
    "& .englishTxt": {
        fontStyle: "normal",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#A046FB",
        marginLeft: '4px',
        fontFamily: "Inter",
    },
    "& .languageTxt": {
        marginLeft: '4px',
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#000",
        fontWeight: "bold",
        fontStyle: "normal",
    },
    "& .enter-otp": {
        fontStyle: "normal",
        textAlign: "center",
        fontSize: "14px",
        color: "#767E85",
        fontWeight: 400,
        cursor: 'pointer',
        fontFamily: "Inter",
    },
    "& .form-content": {
        textAlign: 'center'
    },
    "& .edit-email": {
        fontWeight: 700,
        color: "#A046FB",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "14px",
    },
    "& .otp": {
        marginTop:'-20px',
        height: "49px",
    },
    "& .disabledemail": {
        fontFamily: "Jost",
        fontSize: "30px",
        color: "#0F2220",
    },
    "& .form-otp": {
        marginTop:'-20px',
        textAlign: 'end',
    },
    "& .resend-otp.active": {
        color: "#767E85 !important",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        marginLeft: '6px',
        cursor: 'pointer'
    },
    "& .resend-otp.inactive": {
        fontWeight: 600,
        marginLeft: '6px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        color: "#A046FB !important",
    },
    "& .resend-otp": {
        fontWeight: 600,
        marginLeft: '6px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        color: "#767E85 !important",
        cursor: 'pointer'
    },
    "& .didnot-got": {
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#767E85",
        fontWeight: 400,
        fontStyle: "normal",
    },
    "& .submitBtn": {
        borderRadius: "4px",
        height: 50,
        textTransform: 'capitalize',
        fontWeight: 'bolder',
        background: "#FFF",
        fontFamily: "Jost",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        fontSize: "16px",
        color: "#0F2220",
        border: "2px solid #A046FB",
    },
    "& .timer": {
        fontSize: "14px",
        fontFamily: "Inter",
        textAlign: 'end',
        fontStyle: "normal",
        fontWeight: 400,
        color: "#7D7D7D",
        marginTop:'10px'
    },
    "& .form-created": {
        marginTop: '16px',
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        fontStyle: "normal",
        color: "#767E85",
    },
    "& .form-welcome": {
        fontSize: "20px",
        fontWeight: "bolder",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Inter",
        color: "#535655",
        fontStyle: "normal",
    },
    "& .loginPswrdBtn": {
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        borderRadius: "2px",
        fontWeight: 'bold',
        border: "2px solid #A046FB",
        background: "#A046FB",
        fontFamily: 'Jost',
        marginTop: '140px',
        color: '#fff',
        textTransform: 'capitalize',
        height: '48px',
    },
    "& .loginEmail": {
        fontWeight: 400,
        marginTop: '20px',
        fontSize: "16px",
        color: "var(--grey-base, #939393)",
        fontStyle: "normal",
        fontFamily: "Jost",
    },
    "& .welcome": {
        fontStyle: "normal",
        fontWeight: 600,
        fontFamily: "Inter",
        fontSize: "20px",
        color: "#535655",
    },
    "& .loginPswd": {
        fontWeight: 400,
        fontStyle: "normal",
        fontFamily: "Jost",
        fontSize: "16px",
        color: "var(--grey-base, #939393)",
    },
    "& .btn-grid": {
        textAlign: "center"
    },
    "& .forgetPswd": {
        cursor: 'pointer',
        fontWeight: 500,
        color: "#A046FB",
        fontFamily: "Inter",
        fontStyle: "normal",
        textAlign: 'end',
        fontSize: "12px",
    },
    "& .loginBtn": {
        border: "2px solid #A046FB",
        width: '90%',
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        color: '#fff',
        fontFamily: 'Inter',
        borderRadius: "2px",
        background: "#A046FB",
        height: '48px',
        textTransform: 'capitalize',
    }
})
const DialogStyle = styled(Box)({
    "& .passwordError" : {
        maxWidth:"300px",
        fontSize:"12px",
        color:"#F44336",
        fontFamily:"Inter",
        marginTop:"5px",
        marginLeft:"14px"
    },
    "& .dialog-title": {
        fontSize: "28px",
        color: "#000",
        fontWeight: "bolder",
        fontFamily: "Jost",
    },
    "& .dialog-para": {
        fontStyle: "normal",
        width: '90%',
        fontWeight: 400,
        color: "#5E5E5E",
        fontFamily: "Jost",
        fontSize: "16px",
    },
    "& .change-password-btn": {
        borderRadius: "4px",
        fontFamily: 'Jost',
        textTransform: 'capitalize',
        marginTop: '32px',
        fontSize: '14px',
        fontWeight: 'bolder',
        marginBottom: '40px',
        height: '50px',
        border: "2px solid #A046FB",
        background: "#FFF",
        boxShadow: "0px 16px 32px 0px rgba(48, 49, 51, 0.10)",
        color: '#000',
    },
    "& .ConfirmNewPswd": {
        fontFamily: 'Jost',
        scrollSnapMarginTop: '20px',
        height: '49px',
        marginTop: '20px',
    },
    "& .newPswd": {
        marginTop: '24px',
        height: '49px',
        fontFamily: 'Jost',
    },
})
// Customizable Area End
